import React from 'react';
import { TextField } from '@material-ui/core';
import UploadButton from './UploadButton';
import { apiFileUpload } from '../fetchData';

const FileUpload = ({ classes, ...props }) => {
  const [value, setValue] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const onFileUpload = (file) => {
    if (file !== null) {
      setLoading(true);
      apiFileUpload(file, null, props.category)
        .then((res) => {
          setLoading(false);
          props.onSuccess(res && res.totalUrls ? { createLinks: res } : res);
        })
        .catch((err) => {
          setLoading(false);
          props.onError(err);
        });
    } else {
      props.onError('Please choose a file to upload');
    }
  };

  return (
    <form className={classes.container} noValidate autoComplete="off">
      <TextField
        id="FileUpload"
        // label={props.inputLabel}
        InputLabelProps={{ shrink: true }}
        fullWidth
        className={classes.textField}
        variant="outlined"
        type="file"
        onChange={({ target: { files } }) => {
          setValue(files);
        }}
      />
      <UploadButton
        onClick={() => onFileUpload(value)}
        label="Upload file"
        loading={loading}
      />
    </form>
  );
};

export default FileUpload;
