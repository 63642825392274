import React from 'react';
import { TextField } from '@material-ui/core';
import UploadButton from './UploadButton';
import { getData } from '../fetchData';

const LinksUpload = ({ classes, ...props }) => {
  const [value, setValue] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const onLinkUpload = (urls) => {
    if (urls !== null) {
      setLoading(true);
      getData({
        query: `
          mutation CreateLinks($urls: String!, $category: String) {
            createLinks(urls: $urls, category: $category) {
              totalUrls
              invalidLinks
              duplicatedLinks
              newLinks
              invalidCategorized
              notCategorized
              categorized
              updateCategoryOnHostname
              newRules
            }
          }`,
        variables: { urls, category: props.category }
      })
        .then((res) => {
          setLoading(false);
          props.onSuccess(res);
        })
        .catch((err) => {
          setLoading(false);
          props.onError(err);
        });
    } else {
      props.onError('Please insert links to upload');
    }
  };

  return (
    <form className={classes.container} noValidate autoComplete="off">
      <TextField
        id="LinksUpload"
        label="Links"
        placeholder="Indsæt links..."
        multiline
        fullWidth
        type="text"
        rows="20"
        InputLabelProps={{
          shrink: true
        }}
        value={value || ''}
        onChange={({ target: { value } }) => setValue(value)}
        className={classes.textField}
        margin="normal"
        variant="outlined"
      />
      <UploadButton
        label="Upload link"
        loading={loading}
        onClick={() => {
          onLinkUpload(value);
        }}
      />
    </form>
  );
};

export default LinksUpload;
