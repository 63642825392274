import React from 'react';
import { Route, IndexRoute } from 'react-router';

import Root from './root';
import User from './user';
import Users from './users';
import Subscriptions from './subscriptions';
import Transactions from './transactions';
import ScraperChats from './scraperChats';
import ScraperChat from './scraperChat';
import ScraperChatTrigger from './scraperChatTrigger';
import ScraperChatTriggers from './scraperChatTriggers';
import GoogleClickDashboard from './googleClickDashboard';
import UtmDashboard from './utmDashboard';
import { SubscriptionStatsDashboard } from './subscription-stats';
import { SubscriptionLTVReport } from './subscription-ltv-report';

export default (
  <Route path="akutbolig" component={Root} name="Akutbolig">
    <Route path="users" name="Users">
      <Route path=":userId" component={User} />
      <IndexRoute component={Users} />
    </Route>
    <Route
      path="subscriptions"
      name="Subscriptions"
      component={Subscriptions}
    />
    <Route path="subscription-stats" name="Subscription Stats" component={SubscriptionStatsDashboard} />
    <Route path="subscription-ltv-report" name="Subscription LTV Report" component={SubscriptionLTVReport} />
    <Route path="transactions" name="Transactions" component={Transactions} />
    <Route path="scraperChats" name="Scraper Chats">
      <Route path=":id" component={ScraperChat} />
      <IndexRoute component={ScraperChats} />
    </Route>
    <Route path="scraperChatTriggers" name="Scraper Chats Triggers">
      <Route path="new" component={ScraperChatTrigger} name="new" />
      <Route path=":id" component={ScraperChatTrigger} />
      <IndexRoute component={ScraperChatTriggers} />
    </Route>
    <Route path="google-clicks" name="Google Clicks" component={GoogleClickDashboard} />
    <Route path="utms" name="UTMs" component={UtmDashboard} />

    <IndexRoute component={Users} />
  </Route>
);
