import React from 'react';
import './styles/App.css';

import RefreshIcon from '@material-ui/icons/Refresh';
import AddIcon from '@material-ui/icons/Add';
import Filter from './Inputs/Filter';
import Button from '@material-ui/core/Button';
import LinkUpload from './LinkUpload';
import Input from '@material-ui/core/TextField';

const OptionsPanel = (props) => {
  return (
    <div className="optionsPanel">
      <div style={{ width: '50%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
        <Button
          onClick={() => props.onTriggerReload()}
          variant="contained"
          color="primary"
        >
          Genindlæs
          <RefreshIcon />
        </Button>
        <Filter
          filterValue={props.filterValue}
          categories={props.categories}
          setFilterParam={props.setFilterParam}
        />
        <Input
          id={'freeText'}
          label={'FreeText'}
          type="text"
          value={props.freeText}
          onChange={(ev) => props.updateFreeText(ev.target.value)}
          margin="dense"
          variant="outlined"
        />
        {props.showBack && (
          <Button onClick={() => props.onBack('/biz/linktool')}>Tilbage</Button>
        )}
      </div>
      <div style={{ width: '50%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
        <Button onClick={() => props.setShowModal(true)} variant="contained">
          Tilføj Links
          <AddIcon />
        </Button>
      </div>
      <LinkUpload
        title="Upload Links"
        categories={props.categories}
        open={props.showModal}
        onRequestClose={() => props.setShowModal(false)}
        onSuccess={(res) => {
          props.onTriggerReload();
          props.onPageNotify({
            headline: 'Links uploaded',
            description: res.createLinks
              ? Object.keys(res.createLinks)
                  .reduce((acc, key) => {
                    acc += key + ': ' + res.createLinks[key] + '\n';
                    return acc;
                  }, '')
                  .trim()
              : JSON.stringify(res),
            bsStyle: 'success',
            dismissAfter: 10000
          });
          props.setShowModal(false);
        }}
        onError={(err) => {
          if (typeof err === 'string') {
            props.onPageNotify({
              headline: 'Failed',
              description: err,
              bsStyle: 'danger',
              dismissAfter: 5000
            });
            return;
          }

          console.error(err);
          props.onPageNotify({
            headline: 'Failed',
            description: 'Something went wrong while fetching for API',
            bsStyle: 'danger',
            dismissAfter: 5000
          });
        }}
      />
    </div>
  );
};

export default OptionsPanel;
