import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';
import ScraperTableRow from '../table-row';
import DisplayError from '../modal';
import { fetchScrapers, agentUpdate } from '../../redux/modules/scrapers';
import { desktopNotify } from '../../redux/modules/notification';
import config from '../../config';
import io from 'socket.io-client';

import formatDistanceToNow from 'date-fns/formatDistanceToNow';

type DesktopNotify = { timeout?: number; title: string; options: any };

type Props = {
  fetchScrapers: (data: any) => void;
  agentUpdate: (data: any) => void;
  desktopNotify: (desktopNotify: DesktopNotify) => void;
  settings: any;
  scrapers: any;
};

type State = {
  socket: {
    connected: boolean;
    close: () => void;
    off: (action: string) => void;
  } | null;
  agents: any[];
  sortBy: {
    key: string;
    direction: number;
  };
  dwellings: {
    valid: string | number;
    invalid: string | number;
    raw?: number;
  };
};

class Scrapers extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      socket: null,
      agents: [],
      sortBy: {
        key: 'id',
        direction: 1
      },
      dwellings: {
        valid: '-',
        invalid: '-'
      }
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.scrapers !== this.props.scrapers) {
      this.parseAgents(nextProps.scrapers);
    }
  }

  componentWillUnmount() {
    const { socket } = this.state;
    if (socket && socket.connected) {
      socket.close();
      socket.off('init');
      socket.off('update');
    }
  }

  openSocket = () => {
    const { fetchScrapers, agentUpdate, desktopNotify, settings } = this.props;
    let socket = null;

    if (!socket) {
      socket = io.connect(config.scrapers.socket);
      this.setState({ socket });
    }
    if (socket && !socket.connected) {
      socket.connect();
    }

    socket.on('init', (data) => {
      const { stats } = data;
      fetchScrapers(data);
      if (settings.notifications) {
        desktopNotify({
          title: 'Connected to import framework',
          options: {
            body: `Status: ${stats.status}, up since ${formatDistanceToNow(
              stats.uptime,
              {
                addSuffix: true
              }
            )}`
          }
        });
      }
    });

    socket.on('update', (data) => {
      const { agent } = data;
      const scraper = Object.keys(agent.scrapers);
      const {
        lastRun,
        // checksum: { lastCheck },
        name
      } = agent.scrapers[scraper[0]];
      const ref = this.props.scrapers.getIn(['agents', scraper[0]]);

      if (!ref) {
        return;
      }

      agentUpdate(data);

      if (
        /*(this.state.simple && lastCheck !== ref.get('hashCheck').toJSON()) || */ lastRun &&
        (!ref.get('lastRun') || lastRun.start !== ref.get('lastRun').toJSON())
      ) {
        // agentUpdate(data);

        if (settings.notifications) {
          if (lastRun.properties.invalid) {
            desktopNotify({
              timeout: 3000,
              title: name,
              options: {
                body: `${lastRun.properties.invalid} invalid dwellings`
              }
            });
          }
        }
      }
    });
  };

  closeSocket = () => {
    const { socket } = this.state;
    if (socket && socket.connected) {
      socket.close();
      socket.off('init');
      socket.off('update');
    }
    this.setState({ socket });
  };

  parseAgents(scrapers = this.props.scrapers) {
    let raw = 0;
    let valid = 0;
    let invalid = 0;

    const agents = scrapers.get('agents').toList();

    agents.forEach((agent) => {
      raw += agent.get('allDwellings');
      valid += agent.get('validDwellings');
      invalid += agent.get('invalidDwellings');
    });

    this.setState({ dwellings: { raw, valid, invalid } });

    this.sort(scrapers);
  }

  sort(scrapers = this.props.scrapers) {
    const { key, direction } = this.state.sortBy;

    const connected = scrapers.getIn(['meta', 'uptime']);

    if (connected) {
      const ASC = direction === 1;
      const moveUp = ASC ? 1 : -1;
      const moveDown = ASC ? -1 : 1;
      const agents = scrapers
        .get('agents')
        .toList()
        .sort((a, b) => {
          const x = a.get(key);
          const y = b.get(key);
          return x > y ? moveUp : x < y ? moveDown : 0;
        });

      this.setState({ agents });
    }
  }

  sortBy = (ev) => {
    const { key: curr, direction } = this.state.sortBy;
    const { key: next } = ev.target.dataset;

    const sortBy = {
      key: next,
      direction: curr === next ? direction * -1 : 1
    };

    this.setState({ sortBy }, () => this.sort());
  };

  render() {
    const { socket, agents, dwellings } = this.state;
    const { scrapers } = this.props;

    const connected = scrapers.getIn(['meta', 'uptime']);

    // const agents = connected && scrapers.get('agents').toList();

    const btn =
      !socket || !socket.connected ? (
        <Button bsStyle="primary" onClick={this.openSocket}>
          Connect to scrapers
        </Button>
      ) : (
        <Button bsStyle="danger" onClick={this.closeSocket}>
          Disconnect scrapers
        </Button>
      );

    if (!connected) {
      return btn;
    }

    return (
      <div>
        {btn}

        <div className="table-responsive">
          <table className="table table-condensed">
            <thead>
              <tr>
                <th>Status</th>
                <th>Up since</th>
                <th>Version</th>
                <th>Active scrapers</th>
                <th>Active dwellings</th>
                <th>Raw</th>
                <th>Valid</th>
                <th>Invalid</th>
              </tr>
            </thead>
            <tbody>
              <tr className={scrapers.getIn(['meta', 'class'])}>
                <td>{scrapers.getIn(['meta', 'status'])}</td>
                <td>
                  {formatDistanceToNow(scrapers.getIn(['meta', 'uptime']), {
                    addSuffix: true
                  })}
                </td>
                <td>{scrapers.getIn(['meta', 'version'])}</td>
                <td>{agents.length}</td>
                <td>{scrapers.getIn(['meta', 'activeDwellings'])}</td>
                <td>{dwellings.raw}</td>
                <td>{dwellings.valid}</td>
                <td>{dwellings.invalid}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="table-responsive">
          <table className="table table-striped table-condensed">
            <thead>
              <tr>
                <th data-key="actions" />
                <th
                  style={{ cursor: 'pointer' }}
                  onClick={this.sortBy}
                  data-key="id"
                >
                  #
                </th>
                <th
                  style={{ cursor: 'pointer' }}
                  onClick={this.sortBy}
                  data-key="status"
                >
                  Status
                </th>
                <th
                  style={{ cursor: 'pointer' }}
                  onClick={this.sortBy}
                  data-key="name"
                >
                  Name
                </th>
                <th
                  style={{ cursor: 'pointer' }}
                  onClick={this.sortBy}
                  data-key="link"
                >
                  Link
                </th>
                <th
                  style={{ cursor: 'pointer' }}
                  onClick={this.sortBy}
                  data-key="statusSince"
                >
                  Status since
                </th>
                <th
                  style={{ cursor: 'pointer' }}
                  onClick={this.sortBy}
                  data-key="hashCheck"
                >
                  Hash check
                </th>
                <th
                  style={{ cursor: 'pointer' }}
                  onClick={this.sortBy}
                  data-key="hashChange"
                >
                  Hash change
                </th>
                <th
                  style={{ cursor: 'pointer' }}
                  onClick={this.sortBy}
                  data-key="lastRun"
                >
                  Last run
                </th>
                <th
                  style={{ cursor: 'pointer' }}
                  onClick={this.sortBy}
                  data-key="validDwellings"
                >
                  Valid
                </th>
                <th
                  style={{ cursor: 'pointer' }}
                  onClick={this.sortBy}
                  data-key="invalidDwellings"
                >
                  Invalid
                </th>
              </tr>
            </thead>
            <tbody>
              {agents.map((agent) => (
                <ScraperTableRow key={agent.get('slug')} agent={agent} />
              ))}
            </tbody>
          </table>
        </div>
        <DisplayError />
      </div>
    );
  }
}

export default connect(
  ({ scrapers, auth: { settings } }) => ({ scrapers, settings }),
  {
    fetchScrapers,
    agentUpdate,
    desktopNotify
  }
)(Scrapers);
