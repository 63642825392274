import React, { Component } from 'react';
import apiClient from 'app/utils/api-client';
import Table from 'app/components/mui/Table';
import Button from 'app/components/mui/Button';

const columns = [
  { label: 'Google Click ID', name: 'gclid', valueType: 'string' },
  { label: 'Google Ad Source', name: 'gad_source', valueType: 'string' },
  { label: 'Google Browser ID', name: 'gbraid', valueType: 'string' },
  { label: 'Lease', name: 'leaseId', valueType: 'string', redirectLink: 'https://cp.bbintern.net/dk/dwellings' },
  { label: 'Session', name: 'sessionId', valueType: 'string' },
  { label: 'Pathname', name: 'pathname', valueType: 'string' },
  { label: 'Url Query', name: 'search', valueType: 'copypaste' },
  { label: 'Timestamp', name: 'createdAt', valueType: 'Date' }
];

export default class UtmDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.refresh();
  }

  refresh() {
    apiClient.post('/api/microservice', { data: { action: 'getGoogleClicks', data: { filter: this.state.filter } } }).then((result) => {
      this.setState({ list: result });
    });
  }

  onChange(e, key) {
    this.setState({ filter: { [key]: e.target.value }});
  }

  render() {
    const { list = [] } = this.state;
    const limitRow = 50;

    return (
      <div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {this.refresh ? <Button style={{ margin: '6px' }} size={'small'} label={'Refresh'} onClick={() => this.refresh()}/> : null}
          <input
            onChange={(e) => this.onChange(e, 'gclid')}
            defaultValue={''}
            name="gclid"
            className="form-control"
            style={{ margin: 0, fontWeight: 'normal', width: '100%', marginRight: '10px' }}
            placeholder="Filter by click id"
            />
            <input
            onChange={(e) => this.onChange(e, 'gad_source')}
            defaultValue={''}
            name="gad_source"
            className="form-control"
            style={{ margin: 0, fontWeight: 'normal', width: '100%', marginRight: '10px' }}
            placeholder="Filter by ad source"
            />
            <input
            onChange={(e) => this.onChange(e, 'gbraid')}
            defaultValue={''}
            name="gbraid"
            className="form-control"
            style={{ margin: 0, fontWeight: 'normal', width: '100%', marginRight: '10px' }}
            placeholder="Filter by browser id"
            />
            <input
            onChange={(e) => this.onChange(e, 'pathname')}
            defaultValue={''}
            name="pathname"
            className="form-control"
            style={{ margin: 0, fontWeight: 'normal', width: '100%', marginRight: '10px' }}
            placeholder="Filter by pathname"
            />
            <input
            onChange={(e) => this.onChange(e, 'sessionId')}
            defaultValue={''}
            name="session"
            className="form-control"
            style={{ margin: 0, fontWeight: 'normal', width: '100%', marginRight: '10px' }}
            placeholder="Filter by session"
            />
            <input
              onChange={(e) => this.onChange(e, 'createdAt')}
              defaultValue={''}
              type={'date'}
              name="createdAt"
              className="form-control"
              style={{ margin: 0, fontWeight: 'normal', width: '100%', marginRight: '10px' }}
              placeholder="Filter by timestamp"
            />
        </div>
        <div style={{ display: 'flex' }}>
          <div style={{ margin: '6px' }}>
            <Table
                title={'Google Clicks'}
                columns={columns}
                limitRow={limitRow}
                hideable
                rows={list}
              />
          </div>
        </div>
      </div>
    );
  }
}
