import React, { Component } from 'react';
import apiClient from 'app/utils/api-client';
import Table from 'app/components/mui/Table';
import Button from 'app/components/mui/Button';

const columns = [
  { label: 'Source', name: 'source', valueType: 'string' },
  { label: 'Medium', name: 'medium', valueType: 'string' },
  { label: 'Campaign', name: 'campaign', valueType: 'string' },
  { label: 'Term', name: 'term', valueType: 'string' },
  { label: 'Content', name: 'content', valueType: 'string' },
  { label: 'Lease', name: 'leaseId', valueType: 'string', redirectLink: 'https://cp.bbintern.net/dk/dwellings' },
  { label: 'Session', name: 'sessionId', valueType: 'string' },
  { label: 'Pathname', name: 'pathname', valueType: 'string' },
  { label: 'Url Query', name: 'search', valueType: 'copypaste' },
  { label: 'Timestamp', name: 'createdAt', valueType: 'Date' },
];

export default class UtmDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.refresh();
  }

  refresh() {
    apiClient.post('/api/microservice', { data: { action: 'getUtms', data: { filter: this.state.filter } } }).then((result) => {
      this.setState({ list: result });
    });
  }

  onChange(e, key) {
    this.setState({ filter: { [key]: e.target.value }});
  }

  render() {
    const { list = [] } = this.state;
    const limitRow = 50;

    return (
      <div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {this.refresh ? <Button style={{ margin: '6px' }} size={'small'} label={'Refresh'} onClick={() => this.refresh()}/> : null}
          <input
            onChange={(e) => this.onChange(e, 'source')}
            defaultValue={''}
            name="source"
            className="form-control"
            style={{ margin: 0, fontWeight: 'normal', width: '100%', marginRight: '10px' }}
            placeholder="Filter by source"
            />
            <input
            onChange={(e) => this.onChange(e, 'medium')}
            defaultValue={''}
            name="medium"
            className="form-control"
            style={{ margin: 0, fontWeight: 'normal', width: '100%', marginRight: '10px' }}
            placeholder="Filter by medium"
            />
            <input
            onChange={(e) => this.onChange(e, 'campaign')}
            defaultValue={''}
            name="campaign"
            className="form-control"
            style={{ margin: 0, fontWeight: 'normal', width: '100%', marginRight: '10px' }}
            placeholder="Filter by campaign"
            />
            <input
            onChange={(e) => this.onChange(e, 'term')}
            defaultValue={''}
            name="term"
            className="form-control"
            style={{ margin: 0, fontWeight: 'normal', width: '100%', marginRight: '10px' }}
            placeholder="Filter by term"
            />
            <input
            onChange={(e) => this.onChange(e, 'content')}
            defaultValue={''}
            name="content"
            className="form-control"
            style={{ margin: 0, fontWeight: 'normal', width: '100%', marginRight: '10px' }}
            placeholder="Filter by content"
            />
            <input
            onChange={(e) => this.onChange(e, 'sessionId')}
            defaultValue={''}
            name="session"
            className="form-control"
            style={{ margin: 0, fontWeight: 'normal', width: '100%', marginRight: '10px' }}
            placeholder="Filter by session"
            />
        </div>
        <div style={{ display: 'flex' }}>
          <div style={{ margin: '6px' }}>
            <Table
                title={'UTMs'}
                columns={columns}
                limitRow={limitRow}
                hideable
                rows={list}
              />
          </div>
        </div>
      </div>
    );
  }
}
