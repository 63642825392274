import React from 'react';
import apiClient from 'app/utils/api-client';
import { MenuItem, Select, TextField } from '@material-ui/core';
import Button from 'app/components/mui/Button';
import Table from 'app/components/mui/Table';

type Props = {
  dense?: boolean;
  children: any;
  title?: string;
  style?: { [key: string]: any };
};

const ONE_DAY = 86400000;

const columns = [
  { label: 'User', name: 'externalId', valueType: 'string', url: 'https://cp.bbintern.net/akutbolig/users', link: 'externalId' },
  { label: 'Brand', name: 'brand', valueType: 'string' },
  { label: 'Last4', name: 'last4', valueType: 'string' },
  { label: 'Subscription', name: 'subscriptionId', valueType: 'string' },
  { label: 'Status', name: 'status', valueType: 'string' },
  { label: 'Amount', name: 'amount', valueType: 'number' },
  { label: 'Captured', name: 'captured', valueType: 'number' },
  { label: 'Refunded', name: 'refunded', valueType: 'number' },
  { label: 'Provider ID', name: 'providerId', valueType: 'string' },
  { label: 'Receipt', name: 'receipt', valueType: 'string', exactLink: 'receipt', simpleLinkText: 'receipt link' },
];

export const StripeChargeDashboard = (props: Props): JSX.Element => {
    const past = new Date(new Date().getTime() - 1 * ONE_DAY);
    const [clients, setClients] = React.useState([]);
    const [list, setList] = React.useState([]);

    const [clientName, setClientName] = React.useState('dk.akutbolig');
    const [status, setStatus] = React.useState(null);
    const [last4, setLast4] = React.useState(null);
    const [externalId, setExternalId] = React.useState(null);
    const [createdAfter, setCreatedAt] = React.useState(past);

    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        if(!clients.length) {
            apiClient.post('/api/microservice', { data: { action: 'Clients', data: { input: {} } } }).then((result) => {
                setClients(result.data);
            });
        }
    }, []);

    const refresh = () => {
        const createdAt = new Date(createdAfter);
        createdAt.setHours(0, 0, 0);

        if(!last4 && !externalId && !status) {
          return;
        }

        setLoading(true);
         apiClient.post('/api/microservice', { data: { action: 'getStripeCharges', data: { clientName, match: { last4, externalId, status } } } }).then((data) => {
          if(data) {
            setList(data);
          }
          setLoading(false);
        }).catch(err => setLoading(false));
    };

  return (
    <div>
        <div>
        <span>Client: </span>
        <Select value={clientName} onChange={(ev) => setClientName(ev.target.value)}>
            {clients.map((v) => {
                return <MenuItem value={v}>{v}</MenuItem>;
            })}
        </Select>
        <span>Last4: </span>
        <TextField type="text" id="last4" onChange={(ev) => setLast4(ev.target.value)} value={last4} key="last4" />
        <span>External ID: </span>
        <TextField type="text" id="externalId" onChange={(ev) => setExternalId(ev.target.value)} value={externalId} key="externalId" />
        {/* <span>Created after: </span>
        <TextField type="date" id="createdAt" onChange={(ev) => setCreatedAt(ev.target.value)} value={createdAfter} key="createdAt" /> */}
        <span>Status: </span>
        <Select value={status} onChange={(ev) => setStatus(ev.target.value)}>
          <MenuItem value="Succeeded">Succeeded</MenuItem>
          <MenuItem value="Failed">Failed</MenuItem>
          <MenuItem value="Incomplete">Incomplete</MenuItem>
          <MenuItem value={null}>All</MenuItem>
        </Select>
        <Button style={{ margin: '6px' }} size={'small'} label={'Refresh'} onClick={() => refresh()}/>
        {/* {cursor ? <Button style={{ margin: '6px' }} size={'small'} label={'Load more'} onClick={() => refresh(cursor)}/> : null} */}
        </div>
        { loading ? <p>Loading...</p> : !list?.length ? <p>No charges</p> : ( <Table
            title={`Charges (${list.length})`}
            columns={columns}
            rows={list || []}
            // detailOnClick={(row) => <SubscriptionDetailRow id={row.id}/> }
        />)}
        {/* {list?.length && cursor ? <Button style={{ margin: '6px' }} size={'small'} label={'Load more'} onClick={() => refresh(cursor)}/> : null} */}
    </div>
  );
};
